export const firebaseConfig = {
  apiKey: "AIzaSyCXcaB6KB9QRI56cD_FWLahk1nk1K0SGvo",
  authDomain: "discgolf-media.firebaseapp.com",
  projectId: "discgolf-media",
  storageBucket: "discgolf-media.appspot.com",
  messagingSenderId: "174408656803",
  appId: "1:174408656803:web:274b256aba3059411b1c16",
  measurementId: "G-8S5YSLYBRS",
};

export const appCheck = "6Lci-cAeAAAAAFE_XOKDDtzolH5dtI0owK0IT8P7";
